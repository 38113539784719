import React from 'react';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Box } from "@chakra-ui/react";
const DataDisplay = ({ data, total }) => {
    console.log(data);
    return (
        <Table id="teste" variant="striped" w='96%' size="sm" maxWidth="100%">
            <Thead>
                <Tr>
                    {/* <Td colSpan={2}>Totais:</Td> */}
                    {total.map((item, index) => (
                        // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
                        index === 0 ? (
                            <Td key={index} fontWeight="bold">Totais:</Td>
                        ) : (
                            <>
                                <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
                                {/* {index === 10 && <Td key="empty" />} Adiciona uma <Td> vazia quando index for igual a 10 */}
                            </>
                            // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
                        )
                    ))}
                </Tr>
                {data.length > 0 ? (
                    <Tr>
                        {Object.keys(data[0]).map((key) => (
                            // Restante do seu código
                            <Th textAlign="center" bg={'#5e9ff2'} textColor={'#FFFFFF'} borderWidth="1px" borderColor="black" key={key}>{key}</Th>
                        ))}
                    </Tr>
                ) : ''}
            </Thead>
            <Tbody>
                {data.map((item, index) => (
                    <Tr key={index}>
                        {Object.values(item).map((value, index) => (
                            <Td textAlign="center" key={index}>{value}</Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
        // <Box></Box>
    );
};

export default DataDisplay;
import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

// import html2pdf from 'html2pdf.js';

import DataDisplay from './components/table';
import ModalComponent from './components/modal';
import TabelaFinanceira from './components/teste';



function removeObjectsByKey(objects, keysToRemove) {
    return objects.map(obj => {
        const newObj = {};
        for (let key in obj) {
            if (!keysToRemove.includes(key)) {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    });
}


function calcularPorcentagemDiferenca(valorAntigo, valorNovo) {
    const diferenca = valorAntigo - valorNovo;
    const porcentagem = (diferenca / valorAntigo) * 100;

    // Verifica se a porcentagem possui casas decimais
    if (porcentagem % 1 !== 0) {
        return porcentagem.toFixed(2);
    } else {
        return porcentagem.toFixed(0);
    }
    // return Math.round(porcentagem);
}


function generatePDF(excel, total) {
    const doc = new jsPDF('landscape');
    let tableData = [];

    const data = excel;
    const headers = Object.keys(data[0]).slice(0, 12);
    const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

    for (let i = 0; i < data.length; i++) {
        const values = Object.values(data[i]).slice(0, 12);
        tableData.push(values);
    }

    // const columnWidths = Array(headers.length).fill(10);

    // Adicionar título antes da tabela
    doc.setFontSize(18);
    doc.text('Visão Geral dos Produtos', 15, 15);

    // Adicionar cabeçalhos
    doc.autoTable({
        head: [headers_total, headers], // Adicione os cabeçalhos em ordem
        body: tableData,
        startY: 30, // Ajuste a posição vertical para evitar sobreposição com o título
        columnWidth: 'auto',
        styles: {
            fontSize: 7,
            cellStyles: {
                overflow: 'linebreak', // Quebra de linha automática para células
            }, // Tamanho da fonte para toda a tabela (incluindo cabeçalho e corpo)
        },
    });

    doc.save('relatorio_de_produtos.pdf');
}

function calcularTotalizadores(array) {
    const totalizadores = {};


    array.forEach((obj) => {
        for (const key in obj) {
            const valor = obj[key];

            if (typeof valor === 'string' && valor.includes('R$')) {
                const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
                if (!isNaN(valorNumerico)) {
                    if (totalizadores[key]) {
                        totalizadores[key] += valorNumerico;
                    } else {
                        totalizadores[key] = valorNumerico;
                    }
                }
            } else if (key === 'Qtd. Vendida') {
                const valorNumerico = parseFloat(valor);
                if (!isNaN(valorNumerico)) {
                    if (totalizadores[key]) {
                        totalizadores[key] += valorNumerico;
                    } else {
                        totalizadores[key] = valorNumerico;
                    }
                }
            } else {
                if (!totalizadores[key]) {
                    totalizadores[key] = '';
                }
            }
        }
    });

    const resultado = [];

    for (const key in totalizadores) {
        let value = totalizadores[key];
        if (key !== 'Qtd. Vendida') {
            value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
        }
        resultado.push({
            [`Total ${key}`]: value,
        });
    }

    return resultado;
}

const formatDate = (date) => {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    if (month.length === 1) {
        month = '0' + month;
    }
    let day = date.getDate().toString();
    if (day.length === 1) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
};

function formatarDataHora(valor) {
    if (valor === null || valor === undefined) {
        return "Sem Data";
    }

    const data = new Date(valor);
    if (isNaN(data)) {
        return "Sem Data";
    }

    const dia = ("0" + data.getDate()).slice(-2);
    const mes = ("0" + (data.getMonth() + 1)).slice(-2);
    const ano = data.getFullYear();
    const horas = ("0" + data.getHours()).slice(-2);
    const minutos = ("0" + data.getMinutes()).slice(-2);

    return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

function generateTableData(analitico, dataAtual) {
    // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);
    return analitico.map((item) => ({
        "Código": item._id,
        "Descrição": item.nomeProduto,
        "Qtd. Vendida": item.qtVendido,
        "V. Vendas": formatarValor(item.vTotalVendas),
        "C. Vendas": formatarValor(item.vCustoTotal),
        "P. Médio Custo": formatarValor(item.vCustoTotal / item.qtVendido),
        "V. Desconto": formatarValor(item.vTotalDesconto),
        "Outras Despesas": formatarValor(item.vOutrasDespesas),
        "V. Frete": formatarValor(item.vFrete),
        "V. Total Vendas": formatarValor(
            item.vTotalVendas + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto
        ),
        "V. Lucro": formatarValor(
            item.vTotalVendas + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto - item.vCustoTotal
        ),
        "V. Lucro %": calcularPorcentagemDiferenca(item.vTotalVendas, item.vCustoTotal) + "%",
        "D. da última Venda": formatarDataHora(item.ultimaData),
        "D. Sem Venda": calcularDiferencaDias(item.ultimaData),
        "V. Ultima Venda": formatarValor(item.uValorVendido),
        "P. Médio Vendas": formatarValor(item.vTotalVendas / item.qtVendido),
        "P. Venda Atual": formatarValor(item.vVenda),
        ST: formatarValor(item.vTotalST),
        IPI: formatarValor(item.vTotalIPI),
        "Valor FCP ST": formatarValor(item.vFCPST_Valor),
        "V. Vendas + Imp": formatarValor(
            item.vTotalVendas + item.vTotalST + item.vTotalIPI + item.vFCPST_Valor + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto
        ),
        "Saldo Estoque Atual": item.vEstoqueAtual,
        "Qtd. Ultima Compra": item.quantidadeComprada,
        "D. da última compra": formatarDataHora(item.dUltimaCompra),
        "Ultimo preço de compra": formatarValor(item.vUltimaCompra),
        "V. Estoque preço de custo": formatarValor(item.vEstoqueAtual * item.vCusto),
        "V. Estoque preço de venda": formatarValor(item.vEstoqueAtual * item.vVenda)
    }));
}

// function gerarExcel(dados) {
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(dados);

//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

//   XLSX.writeFile(workbook, 'tabela_produtos.xlsx');
// }

// function gerarExcel(dados, objeto) {
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(dados);

//   // Converter o objeto para uma matriz de valores
//   const objetoValores = Object.values(objeto);

//   // Inserir os valores do objeto na primeira linha do arquivo
//   XLSX.utils.sheet_add_aoa(worksheet, [objetoValores], { origin: 'A1' });

//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

//   XLSX.writeFile(workbook, 'tabela_produtos.xlsx');
// }

function gerarExcel(dados, objeto) {
    const formatarValorParaExcel = (valor) => {
        if (typeof valor === "string" && valor.includes("R$")) {
            const valorLimpo = valor
                .replace("R$", "") // Remove "R$"
                .replace(/\s/g, "") // Remove espaços
                .replace(/\./g, "") // Remove separadores de milhares
                .replace(",", "."); // Substitui vírgula decimal por ponto decimal
            return parseFloat(valorLimpo) || 0; // Converte para número e retorna 0 se NaN
        }
        return valor; // Retorna o valor original se não for string no formato "R$"
    };

    // Mapear e formatar os dados para Excel
    const dadosFormatados = dados.map((item) => {
        const itemFormatado = { ...item };
        for (const chave in itemFormatado) {
            if (typeof itemFormatado[chave] === "string" && itemFormatado[chave].includes("R$")) {
                itemFormatado[chave] = formatarValorParaExcel(itemFormatado[chave]);
            }
        }
        return itemFormatado;
    });

    // Criar workbook e worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dadosFormatados);


    // Extrair os valores do objeto
    const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

    // Obter a matriz existente dos dados
    const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
    const matrizFinal = [objetoValores, ...matrizDados];

    // Converter a matriz final em uma planilha
    const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

    // Adicionar a nova planilha ao workbook
    XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

    XLSX.writeFile(workbook, 'tabela_produtos.xlsx');
}



function formatarValor(valor) {
    if (valor === null || valor === undefined) {
        return "R$ 00,00";
    }

    const valorFormatado = valor.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return valorFormatado;
}

function calcularDiferencaDias(outraData) {
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Número de milissegundos em um dia
    // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);

    const data1 = new Date();
    const data2 = new Date(outraData);
    // console.log(data1, data2)

    data1.setHours(0, 0, 0, 0);
    data2.setHours(0, 0, 0, 0);

    // Calcula a diferença em milissegundos entre as datas
    const diferencaMilissegundos = Math.abs(data1 - data2);

    // Calcula a diferença em dias
    const diferencaDias = Math.round(diferencaMilissegundos / umDiaEmMilissegundos);

    return diferencaDias;
}



export default function UserReports() {
    const tabelaRef = useRef();
    const [analitico, setAnalitico] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [ano, setAno] = useState('2023');
    const [tipoData, setTipoData] = useState('DataCompetencia');
    const [empresa, setEmpresa] = useState('');
    const [diaAtual, setDia] = useState('');
    const [filtros, setFiltros] = useState({});
    const [totalItems, setTotalItems] = useState(0);
    const [inicio, setInicio] = useState('');
    const [fim, setFim] = useState('');
    const history = useHistory();
    const [token, setToken] = useState('');
    const [dados, setDados] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [excel, setExcel] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [camposTotal, setCampos] = useState('');
    const [centro, setCentro] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);

    // const gerarPDF = () => {
    //     // Obtenha a referência da tabela
    //     const tabelaElement = tabelaRef.current;

    //     console.log(tabelaRef.current)

    //     // Configurações para o html2pdf
    //     const options = {
    //         margin: 10,
    //         filename: 'relatorio.pdf',gerarPDF
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //     };

    //     // Use html2pdf para converter a tabela em um PDF
    //     html2pdf().from(tabelaElement).set(options).output().then((pdfBlob) => {
    //         // Cria um link para o arquivo Blob (PDF) e simula um clique nele para iniciar o download
    //         const link = document.createElement('a');
    //         link.href = URL.createObjectURL(pdfBlob);
    //         link.download = 'relatorio.pdf';
    //         link.click();
    //       });
    // };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleValuesSelected = (values) => {
        setSelectedValues(values);
    };

    const handleClickExcel = () => {
        gerarExcel(excel, total);
    };

    const handleClickPdf = () => {
        generatePDF(excel, total);
    };

    async function index(data) {

        const dados_formatados = await generateTableData(data, diaAtual)
        setCampos(dados_formatados)
        const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
        // console.log(dados_formatados)
        const totais = calcularTotalizadores(objFinal)
        // console.log("dados formatados")
        console.log(objFinal, totais)
        setExcel(objFinal)
        setTotal(totais)

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const itemsToShow = objFinal.slice(startIndex, endIndex);
        // console.log(itemsToShow);
        setAnalitico(itemsToShow)
    }

    const getData = async () => {
        try {
            const value = await AsyncStorage.getItem('@storage_Key')
            if (value !== null) {
                return value;// value previously stored
            }
        } catch (e) {
            // error reading value
        }
    }



    useEffect(async () => {
        const param = {};
        const key = await getData();

        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const formattedFirstDay = formatDate(firstDayOfMonth);
        const formattedLastDay = formatDate(lastDayOfMonth);

        setInicio(formattedFirstDay);
        setFim(formattedLastDay);

        if (typeof key !== 'undefined' && key !== null) {
            param.user = key;
            setFiltros(param);

            const fetchData = async () => {
                setIsLoading(true);

                try {
                    const [filtrosData, dadosData] = await Promise.all([
                        api.get('/plano-filter', { params: param, timeout: 500000 }),
                        api.get('/centrocusto', { params: param, timeout: 5000000 }),
                    ]);

                    const filtros = filtrosData.data;
                    const dados = dadosData.data;

                    console.log(dados)

                    setToken(key);
                    setDados(dados);
                    // setTotalItems(dados.length);

                    // const select_ano = [...new Set(filtros[0].map(item => item.year))];
                    const select_empresas = [...new Set(filtros[1].map(item => item.NomeFantasia))];
                    const select_centro = [...new Set(filtros[2].map(item => item.Nome))];

                    // setDia(filtros[3]);
                    const selectEmpresas = document.getElementById("select-empresas");
                    select_empresas.forEach(empresa => {
                        const option = document.createElement("option");
                        option.value = empresa;
                        option.text = empresa;
                        selectEmpresas.appendChild(option);
                    });

                    // const selectAno = document.getElementById("ano");
                    // select_ano.forEach(ano => {
                    //     const option = document.createElement("option");
                    //     option.value = ano;
                    //     option.text = ano;
                    //     selectAno.appendChild(option);
                    // });

                    const selectCentro = document.getElementById("select-centro");
                    select_centro.forEach(origens => {
                        const option = document.createElement("option");
                        option.value = origens;
                        option.text = origens;
                        selectCentro.appendChild(option);
                    });

                    if (currentPage === 1) {
                        // await index(dados);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchData();
        } else {
            history.push('/');
        }
    }, []);

    //executa a paginação na alteração de seus estados
    //   useEffect(() => {
    //     // index(dados);
    //   }, [currentPage, itemsPerPage, selectedValues]);


    //aplica os filtros
    const handleFilter = async () => {
        const data = {}

        if ((typeof token !== 'undefined') && token !== null) {
            data.user = token

            if ((empresa !== '') && empresa !== null) {
                data.empresa = empresa
            }

            if ((ano !== '') && ano !== null) {
                data.ano = ano
            }

            if ((tipoData !== '') && tipoData !== null) {
                data.tipoData = tipoData
            }

            if ((centro !== '') && centro !== null) {
                data.centro = centro
            }

            if (inicio !== null && inicio !== '') {
                if (fim !== null && fim !== '') {
                    data.fim = fim
                    data.inicio = inicio
                } else {
                    alert("preencher os dois periodos");
                }
            }

            setFiltros(data)

            // if (Object.keys(data).length !== 0) {
            //     setCurrentPage(1)
            //     setItemsPerPage(20)
            // }

            try {
                setIsLoading(true);
                api.get('/centrocusto', { params: data, timeout: 1000000 })
                    .then(response => {
                        console.log(response.data)

                        // setDados(response.data);

                        if (response.data.length !== 0) {
                            // console.log(response.data[0])
                            setDados(response.data);
                            // setTotalItems(response.data[0].length);
                        } else {
                            setDados(response.data);
                            // handlePageChange(1)
                            // setTotalItems(20);
                        }

                        if (currentPage === 1) {
                            // index(response.data[0]);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    }).finally(() => {
                        setIsLoading(false)
                    });;

                // console.log(data[0].length)
            } catch (error) {
                console.error(error);
            }
        } else {
            history.push('/');
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        // handleSubmit(); // Atualiza os dados ao alterar a página
    };


    const handleItemsPerPageChange = (newItens) => {
        setItemsPerPage(newItens);
        setCurrentPage(1); // Reset currentPage when changing items per page
        // handleSubmit();
    };


    return (
        <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
            {isLoading && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
                    zIndex="9999"
                >
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        color="blue.500"
                        size="xl"
                    />
                </Box>
            )}
            <SimpleGrid columns={[1, 2, 3, 4]} spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
                        <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
                            <option value="">Todas</option>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Centro de Custo:</FormLabel>
                        <Select id="select-centro" onChange={(e) => setCentro(e.target.value)}>
                            <option value="">Todos</option>
                        </Select>
                    </FormControl>
                </Box>
                {/* <Box maxW="md">
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Ano Selecionado:</FormLabel>
                        <Select id="ano" value={ano} onChange={(e) => setAno(e.target.value)}>
                            {/* <option value="2023">2023</option> 
                        </Select>
                    </FormControl>
                </Box> */}
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
                        <Select id="tipoData" value={tipoData} onChange={(e) => setTipoData(e.target.value)}>
                            <option value="DataCompetencia">Data Competencia</option>
                            <option value="DataVencimento">Data Vencimento</option>
                            <option value="DataPagamento">Data Pagamento</option>
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
                        <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
                        <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
                    </FormControl>
                </Box>
                <Box mt="auto">
                    <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
                </Box>
            </SimpleGrid>
            {/* <SimpleGrid mt='1%'>
                <Button size="sm" w="10%" onClick={handleOpenModal}>Selecionar Campos</Button>
                <ModalComponent
                    data={camposTotal}
                    onValuesSelected={handleValuesSelected}
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    selectedValues={selectedValues}
                />
                <Box mt='1%'>
                    <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
                </Box>
            </SimpleGrid> */}
            <SimpleGrid mt='2%'>
                <TableContainer w='96%' ml='2%' overflowX='auto'>
                    <Box maxW='100%'>
                        {dados && (
                            <TabelaFinanceira ref={tabelaRef} dados={dados} />
                        )}
                    </Box>
                </TableContainer>
                {/* {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )} */}
                <Box mt="auto">
                    {/* <Button colorScheme="blue" onClick={gerarPDF}>PDF</Button> */}
                </Box>
            </SimpleGrid>
            <Box display='flex'>
                {/* <Pagination
                    totalItems={totalItems}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                    onLimitChange={handleItemsPerPageChange}
                /> */}
                {/* <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
                <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button> */}
            </Box>
        </Box>
    );
}

import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';


import { MultiSelect } from "react-multi-select-component";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DataDisplay from './components/table';
import ModalComponent from './components/modal';


function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}


function calcularPorcentagemDiferenca(valorAntigo, valorNovo) {
  const diferenca = valorAntigo - valorNovo;
  const porcentagem = (diferenca / valorAntigo) * 100;

  // Verifica se a porcentagem possui casas decimais
  if (porcentagem % 1 !== 0) {
    return porcentagem.toFixed(2);
  } else {
    return porcentagem.toFixed(0);
  }
  // return Math.round(porcentagem);
}

function calcularPorcentagem(objetos) {
  // Calcula o valor total

  // console.log(objeto["V. Vendas"]);
  // console.log(objetos);

  // const valorTotal = objetos.reduce((total, objeto) => {
  //   const valor = parseFloat(objeto["V. Vendas"].replace(/[^\d,-]+/g, '').replace(',', '.'));
  //   return total + valor;
  // }, 0);

  // // Adiciona a porcentagem a cada objeto
  // objetos.forEach(objeto => {
  //   const valor = parseFloat(objeto["V. Vendas"].replace(/[^\d,-]+/g, '').replace(',', '.'));
  //   const porcentagem = (valor / valorTotal) * 100;

  //   objeto.Porcentagem = parseFloat(porcentagem.toFixed(2));
  // });

  // Ordena os objetos pelo maior valor de porcentagem
  objetos.sort((a, b) => b.Porcentagem - a.Porcentagem);

  if (objetos.length === 1) {
    objetos[0].Classe = (
      <span style={{ color: 'green' }}>A</span>
    );
    return objetos;
  }

  // Adiciona a classe a cada objeto
  // Define os limites das classes
  const limiteA = 80;
  const limiteB = 95;

  // Adiciona a classe a cada objeto
  let porcentagemAcumulada = 0;
  objetos.forEach(objeto => {
    if (porcentagemAcumulada + objeto.Porcentagem <= limiteA) {
      objeto.Classe = (
        <span style={{ color: 'green' }}>A</span>
      );
    } else if (porcentagemAcumulada < limiteB) {
      objeto.Classe = (
        <span style={{ color: 'blue' }}>B</span>
      );
    } else {
      objeto.Classe = (
        <span style={{ color: 'red' }}>C</span>
      );
    }

    porcentagemAcumulada += objeto.Porcentagem;
  });


  return objetos;
}



function generatePDF(excel, total, filtros) {
  const doc = new jsPDF('landscape');
  let tableData = [];
  let tableFiltro = transformaFiltros([filtros]);
  let result = [];

  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const values = Object.values(data[i]).slice(0, 12);
    tableData.push(values);
  }

  // const columnWidths = Array(headers.length).fill(10);

  // Adicionar título antes da tabela
  doc.setFontSize(18);
  doc.text('Visão Geral dos Produtos', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros

    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');

    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);

    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);

    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;

    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }

    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;
  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título
    columnWidth: 'auto',
    styles: {
      fontSize: 7,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      }, // Tamanho da fonte para toda a tabela (incluindo cabeçalho e corpo)
    },
  });

  doc.save('relatorio_de_produtos.pdf');
}

function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Vendida') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Porcentagem') {
        totalizadores[key] = '100%'
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if (key !== 'Qtd. Vendida') {
      value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};

function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(valor);
  if (isNaN(data)) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();
  const horas = ("0" + data.getHours()).slice(-2);
  const minutos = ("0" + data.getMinutes()).slice(-2);

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

function generateTableData(analitico, dataAtual) {
  
  if (analitico.length === 0) {
    const keys = [
      "Código",
      "Descrição",
      "Qtd. Vendida",
      "Saldo Estoque Atual",
      "D. da última compra",
      "Qtd. Ultima Compra",
      "Sugestão de compra",
      "V. Preço De Custo",
      "P. Venda Atual",
      "Estoque Crítico Item",
      "Qtd. Mínima Item"
      // "Vendas Ultimos 3 meses",
      // "V. Vendas",
      // "C. Vendas",
      // "P. Médio Custo",
      // "V. Desconto",
      // "Outras Despesas",
      // "V. Frete",
      // "V. Total Vendas",
      // "Ticket Medio",
      // "Classe",
      // "Porcentagem",
      // "V. Lucro",
      // "V. Lucro %",
      // "D. da última Venda",
      // "D. Sem Venda",
      // "V. Ultima Venda",
      // "P. Médio Vendas",
      // "ST",
      // "IPI",
      // "Valor FCP ST",
      // "V. Vendas + Imp",
      // "Ultimo preço de compra",
      // "V. Estoque Ultimo preço de compra",
      // "V. Estoque preço de custo",
      // "V. Estoque preço de venda"
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }
  // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number); - item.vTotalDesconto
  return analitico.map((item) => ({
    "Código": item._id,
    "Descrição": item.nomeProduto,
    "Qtd. Vendida": item.qtVendido,
    "Saldo Estoque Atual": item.vEstoqueAtual,
    "D. da última compra": formatarDataHora(item.dUltimaCompra),
    "Qtd. Ultima Compra": item.quantidadeComprada,
    "Sugestão de compra": item.qtVendido - item.vEstoqueAtual,
    // "Vendas Ultimos 3 meses": item.qtUltimos3Meses,
    // "V. Produtos": formatarValor(item.vTotalVendas),
    // "C. Vendas": formatarValor(item.vCustoTotal),
    // "P. Médio Custo": formatarValor(item.vCustoTotal / item.qtVendido),
    // "V. Desconto": formatarValor(item.vTotalDesconto),
    // "Outras Despesas": formatarValor(item.vOutrasDespesas),
    // "V. Frete": formatarValor(item.vFrete),
    // "V. Vendas": formatarValor(
    //   item.vTotalVendas + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto
    // ),
    // "V. Vendas + Imp": formatarValor(
    //   item.vTotalVendas + item.vTotalST + item.vTotalIPI + item.vFCPST_Valor + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto
    // ),
    // "Ticket Medio": formatarValor(item.vTotalVendas / item.qtVendido),
    // "Classe": '',
    // "Porcentagem": '',
    // "V. Lucro": formatarValor(
    //   item.vTotalVendas + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto - item.vCustoTotal
    // ),
    // "V. Lucro %": calcularPorcentagemDiferenca(item.vTotalVendas + item.vOutrasDespesas + item.vValorFrete - item.vTotalDesconto, item.vCustoTotal) + "%",
    // "D. da última Venda": formatarDataHora(item.ultimaData),
    // "D. Sem Venda": calcularDiferencaDias(item.ultimaData),
    // "V. Ultima Venda": formatarValor(item.uValorVendido),
    // "P. Médio Vendas": formatarValor(item.vTotalVendas / item.qtVendido),
    // "ST": formatarValor(item.vTotalST),
    // "IPI": formatarValor(item.vTotalIPI),
    // "Valor FCP ST": formatarValor(item.vFCPST_Valor),
    "V. Preço De Custo": formatarValor(item.vCusto),
    "P. Venda Atual": formatarValor(item.vVenda),
    "Estoque Crítico Item": item.vEstoqueMinimo,
    "Qtd. Mínima Item": item.vEstoqueRisco
    // "Ultimo preço de compra": formatarValor(item.vUltimaCompra),
    // "V. Estoque Ultimo preço de compra": formatarValor(item.vEstoqueAtual * item.vUltimaCompra),
    // "V. Estoque preço de custo": formatarValor(item.vEstoqueAtual * item.vCusto),
    // "V. Estoque preço de venda": formatarValor(item.vEstoqueAtual * item.vVenda)
  }));
}


// function gerarExcel(dados, objeto) {
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(dados);

//   // Extrair os valores do objeto
//   const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

//   // Obter a matriz existente dos dados
//   const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

//   // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
//   const matrizFinal = [objetoValores, ...matrizDados];

//   // Converter a matriz final em uma planilha
//   const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

//   // Adicionar a nova planilha ao workbook
//   XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

//   XLSX.writeFile(workbook, 'tabela_produtos.xlsx');
// }


function gerarExcel(dados, objeto) {
  // Função para converter valores no formato "R$ 1.234,56" para float
  const formatarValorParaExcel = (valor) => {
    if (typeof valor === "string" && valor.includes("R$")) {
      const valorLimpo = valor
        .replace("R$", "") // Remove "R$"
        .replace(/\s/g, "") // Remove espaços
        .replace(/\./g, "") // Remove separadores de milhares
        .replace(",", "."); // Substitui vírgula decimal por ponto decimal
      return parseFloat(valorLimpo) || 0; // Converte para número e retorna 0 se NaN
    }
    return valor; // Retorna o valor original se não for string no formato "R$"
  };

  // Mapear e formatar os dados para Excel
  const dadosFormatados = dados.map((item) => {
    const itemFormatado = { ...item };
    for (const chave in itemFormatado) {
      if (typeof itemFormatado[chave] === "string" && itemFormatado[chave].includes("R$")) {
        itemFormatado[chave] = formatarValorParaExcel(itemFormatado[chave]);
      }
    }
    return itemFormatado;
  });

  // Criar workbook e worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dadosFormatados);

  // Extrair os valores do objeto adicional
  const objetoValores = Object.values(objeto).map((item) => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, "Sheet 1");

  // Salvar o arquivo Excel
  XLSX.writeFile(workbook, "tabela_produtos.xlsx");
}



function transformaFiltros(data) {
  return data.map((item) => {
    const filtro = {};

    if (item.status) {
      filtro['Status: '] = (item.status);
    }

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.tabela) {
      filtro['Tabela de Preço: '] = item.tabela;
    }

    if (item.categoriaP) {
      filtro['Categoria Produto: '] = item.categoriaP;
    }

    if (item.genero) {
      filtro['Genero Produto: '] = item.genero;
    }

    if (item.deposito) {
      filtro['Deposito: '] = item.deposito;
    }

    if (item.prod) {
      filtro['Produto: '] = item.prod;
    }

    if (item.origem) {
      filtro['Origem da Venda: '] = item.origem;
    }

    if (item.vendedor) {
      filtro['Vendedor: '] = item.vendedor;
    }

    if (item.plano) {
      filtro['Plano de Contas: '] = item.plano;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}




function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

function calcularDiferencaDias(outraData) {
  const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // Número de milissegundos em um dia
  // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);

  const data1 = new Date();
  const data2 = new Date(outraData);
  // console.log(data1, data2)

  data1.setHours(0, 0, 0, 0);
  data2.setHours(0, 0, 0, 0);

  // Calcula a diferença em milissegundos entre as datas
  const diferencaMilissegundos = Math.abs(data1 - data2);

  // Calcula a diferença em dias
  const diferencaDias = Math.round(diferencaMilissegundos / umDiaEmMilissegundos);

  return diferencaDias;
}

export default function UserReports() {
  const [analitico, setAnalitico] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [status, setStatus] = useState('Pedido Faturado');
  const [empresa, setEmpresa] = useState('');
  const [origem, setOrigens] = useState('');
  const [prod, setFiltroProd] = useState('');
  const [categVenda, setCategVenda] = useState('');
  const [deposito, setDeposito] = useState('');
  const [vendedor, setVendedor] = useState('');
  const [tabela, setTabela] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [TipoData, setTipoData] = useState('Data');
  const [generoProd, setGeneroProd] = useState('');
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [vendedores, setVendedores] = useState('');
  const [selectedVendedores, setSelectedVendedores] = useState([]);
  const [marcas, setMarcas] = useState('');
  const [selectedMarcas, setSelectedMarcas] = useState([]);
  const [categProd, setCategProd] = useState('');
  const [selectedCateg, setSelectedCateg] = useState([]);
  const [selectOrigem, setSelectOrigem] = useState([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [intervalo, setIntervalo] = useState('1');
  const [estoque, setEstoque] = useState([]);


  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {

    const dados_formatados = await generateTableData(data, diaAtual)
    // const processamentoClasse = calcularPorcentagem(dados_formatados)
    // setCampos(processamentoClasse)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    // console.log(dados_formatados)
    const totais = calcularTotalizadores(objFinal)
    // console.log("dados formatados")
    // console.log(objFinal, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);

    // const itemsToShow = objFinal
    //   .slice(startIndex, endIndex)
    //   .map(item => ({
    //     ...item,
    //     // Substitui valores inválidos por 0 ou uma string vazia
    //     ...Object.keys(item).reduce((acc, key) => {
          // acc[key] = isNaN(item[key]) || item[key] === null ? 0 : item[key];
    //       return acc;
    //     }, {})
    //   }));
    // setAnalitico(itemsToShow);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/produtos_filtros', { params: param, timeout: 10000000 }),
            api.get('/dados_sugestao', { params: param, timeout: 10000000 }),
          ]);

          const filtros = filtrosData.data;
          const dados = dadosData.data[0];

          console.log(dados);

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);

          console.log(filtros);

          const select_empresas = [...new Set(filtros[0].map(item => item.NomeFantasia))];
          const select_origens = [...new Set(filtros[1].map(item => item.origemVenda))];
          const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria))];
          const select_dep = [...new Set(filtros[3].map(item => item.Deposito))];
          const select_tab = [...new Set(filtros[4].map(item => item.TabelaDePreco))];
          const select_genero_prod = [...new Set(filtros[5].map(item => item.Genero))];


          // console.log(select_origens);

          // const origens = {};
          // select_origens.forEach(value => {
          //   origens[value] = value;
          // });

          const origens = select_origens.map(value => ({
            value: value,
            label: value
          }));
          setOrigens(origens);

          const empre = select_empresas.map(value => ({
            value: value,
            label: value
          }));
          setEmpresa(empre);
          // console.log(origens)

          const vendedoresFiltros = filtros[6];
          const marcasFriltros = filtros[7];
          const select_categ_prod = filtros[8];


          if (vendedoresFiltros) {
            const vend = vendedoresFiltros.map(vendedor => ({
              value: vendedor._id,
              label: vendedor.NomeFantasia
            }));
            setVendedores(vend);
            // console.log(vend); // Aqui você verá os dados atualizados
          }

          if (select_categ_prod) {
            const ctgp = select_categ_prod.map(categp => ({
              value: categp._id,
              label: categp.Nome
            }));
            setCategProd(ctgp);
            // console.log(vend); // Aqui você verá os dados atualizados
          }

          if (marcasFriltros) {
            const mar = marcasFriltros.map(marc => ({
              value: marc._id,
              label: marc.Nome
            }));
            setMarcas(mar);
            // console.log(vend); // Aqui você verá os dados atualizados
          }

          setDia(filtros[3]);

          const selectCateg = document.getElementById("select-categoria-venda");
          select_categ.forEach(categVenda => {
            const option = document.createElement("option");
            option.value = categVenda;
            option.text = categVenda;
            selectCateg.appendChild(option);
          });

          const selectDep = document.getElementById("select-deposito");
          select_dep.forEach(deposito => {
            const option = document.createElement("option");
            option.value = deposito;
            option.text = deposito;
            selectDep.appendChild(option);
          });

          const selectTab = document.getElementById("select-tabela");
          select_tab.forEach(tabela => {
            const option = document.createElement("option");
            option.value = tabela;
            option.text = tabela;
            selectTab.appendChild(option);
          });

          const selectGenero = document.getElementById("select-genero-prod");
          select_genero_prod.forEach(genero => {
            const option = document.createElement("option");
            option.value = genero;
            option.text = genero;
            selectGenero.appendChild(option);
          });

          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((status !== '') && status !== null) {
        data.status = status
      }

      if ((TipoData !== '') && TipoData !== null) {
        data.tipoData = TipoData
      }

      if ((selectedEmpresas !== '') && selectedEmpresas !== null) {
        data.empresa = selectedEmpresas
      }

      if ((selectedVendedores !== '') && selectedVendedores !== null) {
        data.vendedor = selectedVendedores
      }

      if ((intervalo !== '') && intervalo !== null) {
        data.intervalo = intervalo
      }

      if ((generoProd !== '') && generoProd !== null) {
        data.genero = generoProd
      }

      if ((selectedCateg !== '') && selectedCateg !== null) {
        data.categoriaP = selectedCateg
      }

      if ((tabela !== '') && tabela !== null) {
        data.tabela = tabela
      }

      if ((deposito !== '') && deposito !== null) {
        data.deposito = deposito
      }

      if ((selectedMarcas !== '') && selectedMarcas !== null) {
        data.marcas = selectedMarcas
      }

      if ((selectOrigem !== '') && selectOrigem !== null) {
        data.origem = selectOrigem
      }

      if ((prod !== '') && prod !== null) {
        data.prod = prod
      }

      if ((categVenda !== '') && categVenda !== null) {
        data.categVenda = categVenda
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/dados_sugestao', { params: data, timeout: 1000000 })
          .then(response => {
            // console.log(response.data)

            if (response.data[0].length !== 0) {
              // console.log(response.data[0])
              setDados(response.data[0]);
              setTotalItems(response.data[0].length);
            } else {
              setDados(response.data[0]);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data[0]);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid border="1px solid black" columns={[1, 2, 3, 4]} spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}>
        {/* <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Origem da Venda:</FormLabel>
            <Select id="select-origens" onChange={(e) => setOrigens(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Origem da Venda:</FormLabel>
            <MultiSelect
              options={origem}
              value={selectOrigem}
              onChange={setSelectOrigem}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <MultiSelect
              options={empresa}
              value={selectedEmpresas}
              onChange={setSelectedEmpresas}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda:</FormLabel>
            <Select onChange={(e) => setStatus(e.target.value)}>
              <option value="Pedido Faturado">Pedido Faturado</option>
              <option value="all">Todos</option>
              <option value="Pedido">Pedido</option>
              <option value="Orçamento">Orçamento</option>
              <option value="Pedido Nao Faturado">Pedido não Faturado</option>
              <option value="Pedido Aprovado Sem Faturamento">Pedido Aprovado Sem Faturamento</option>
            </Select>
          </FormControl>
        </Box> */}
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Estoque:</FormLabel>
            <Select onChange={(e) => setEstoque(e.target.value)}>
              <option value="0">Todos</option>
              <option value="1">Positivos</option>
              <option value="2">Negativos</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Categoria da Venda:</FormLabel>
            <Select id="select-categoria-venda" onChange={(e) => setCategVenda(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tabela de Preço:</FormLabel>
            <Select id="select-tabela" onChange={(e) => setTabela(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Produto ou Codigo:</FormLabel>
            <Input type="input" id="prod" onChange={(e) => setFiltroProd(e.target.value)} />
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Categoria Produto:</FormLabel>
            <Select id="select-categ-prod" onChange={(e) => setCategProd(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Categoria Produto:</FormLabel>
            <MultiSelect
              options={categProd}
              value={selectedCateg}
              onChange={setSelectedCateg}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Genero Produto:</FormLabel>
            <Select id="select-genero-prod" value={generoProd} onChange={(e) => setGeneroProd(e.target.value)}>
              <option value="all">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Marca:</FormLabel>
            <MultiSelect
              options={marcas}
              value={selectedMarcas}
              onChange={setSelectedMarcas}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Deposito:</FormLabel>
            <Select id="select-deposito" onChange={(e) => setDeposito(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Vendedores:</FormLabel>
            <MultiSelect
              options={vendedores}
              value={selectedVendedores}
              onChange={setSelectedVendedores}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Intervalo Pesquisa:</FormLabel>
            <Select onChange={(e) => setIntervalo(e.target.value)}>
              <option value="1">Ultimos 30 dias</option>
              <option value="2">Ultimos 60 dias</option>
              <option value="3">Ultimos 6 Meses</option>
            </Select>
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="Data">Data da Venda</option>
              <option value="DataAprovacao">Data Aprovação</option>
              <option value="DataFaturamento">Data Faturamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box> */}
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt='1%'>
        <Button border="1px solid black" size="sm" w="10%" onClick={handleOpenModal}>Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        {/* <ModalComponent data={analitico} onValuesSelected={handleValuesSelected} isOpen={isOpen} onClose={handleCloseModal} valuesCheck={selectedValues} /> */}
        <Box mt='1%'>
          <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='2%'>
        <TableContainer w='96%' ml='2%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}

import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner, SelectField } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import { HiOutlineShoppingCart } from "react-icons/hi";
import IconBox from "components/icons/IconBox";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';
import { MultiSelect } from "react-multi-select-component";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DataDisplay from './components/table';
import ModalComponent from './components/modal';


function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}


function generatePDF(excel, total, filtros) {

  console.log()

  let tableFiltro = transformaFiltros([filtros]);
  let result = [];

  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }

  const doc = new jsPDF('landscape');
  let tableData = [];

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const rowValues = Object.values(data[i]).slice(0, 12);
    const convertedRowValues = rowValues.map((value) => {
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        return value.props.children.toString(); // Converte o objeto em uma string
      }
      return value;
    });
    tableData.push(convertedRowValues);
  }


  doc.setFontSize(18);
  doc.text('Relatório Quadro de Vendas', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros

    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');

    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);

    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);

    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;

    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }

    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save('quadro_diario.pdf');
}


function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Quantidade') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if (key !== 'Quantidade') {
      if (key === 'Ticket Medio') {
        const ticketMedioValue = parseFloat(totalizadores['VT. Vendas']) / totalizadores['Quantidade'];
        value = ticketMedioValue !== NaN ? formatarValor(ticketMedioValue) : '';
      } else {
        value = totalizadores[key] !== '' ? formatarValor(totalizadores[key]) : '';
      }
      // value = totalizadores[key] !== '' ? `${formatarValor(totalizadores[key])}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};


function formatarDataHora(valor) {
  if (valor === null || valor === undefined) {
    return "Sem Data";
  }

  const data = new Date(`${valor}T00:00`);
  if (isNaN(data)) {
    return "Sem Data";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();

  return `${dia}/${mes}/${ano}`;
}

function transformaFiltros(data) {

  console.log(data)
  return data.map((item) => {
    const filtro = {};

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.TipoData) {
      filtro['Tipo Data: '] = item.TipoData;
    }

    if (item.formas) {
      filtro['Forma Pagamento: '] = item.formas;
    }

    if (item.grupo) {
      filtro['Grupo Lançamento: '] = item.grupo;
    }

    if (item.cliente) {
      filtro['Cliente: '] = item.cliente;
    }

    if (item.grupoPessoa) {
      filtro['Grupo do Cliente: '] = item.grupoPessoa;
    }

    if (item.centro) {
      filtro['Centro de Custo: '] = item.centro;
    }

    if (item.banco) {
      filtro['Banco: '] = item.banco;
    }

    if (item.cidade) {
      filtro['Cidade: '] = item.cidade;
    }

    if (item.uf) {
      filtro['UF:'] = item.uf;
    }

    if (item.vendedor) {
      filtro['Vendedor: '] = item.vendedor;
    }

    if (item.plano) {
      filtro['Plano de Contas: '] = item.plano;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}

function generateTableData(analitico, dataAtual) {
  console.log(analitico)
  if (analitico.length === 0) {
    const keys = [
      "Data",
      "Quantidade",
      "V. PRODUTOS",
      "V. Total Prduto",
      "Ticket Medio",
      "V. DEVOLVIDO",
      "Desconto",
      "Frete",
      "Outras Despesas",
      "ST",
      "IPI",
      "FECOEP",
      // "" : item.vFCPST_Valor,
      // "" : item.vValorFrete,
      "V. LIQUIDO",
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }
  // const [anoAtual, mesAtual, diaAtual] = dataAtual.split('-').map(Number);
  return analitico.map((item) => ({
    "Data": formatarDataHora(item._id.Data),
    "Quantidade": item.quantidade,
    "VT. Produtos": formatarValor(item.vTotal + item.VDesconto),
    "VT. Vendas": formatarValor(item.vTotal + item.Frete + item.vOutrasDespesas),
    "VT. Vendas + IMP": formatarValor(item.vTotal + item.Frete + item.vTotalST + item.vTotalIPI + item.vFCPST_Valor + item.vOutrasDespesas),
    "Ticket Medio": formatarValor((item.ValorFinal) / item.quantidade),
    // "VT. Vendas": formatarValor(item.ValorFinal),
    // "VT. Vendas + IMP" : formatarValor(item.ValorFinal + item.vTotalST + item.vTotalIPI + item.vFCPST_Valor),
    // "Ticket Medio": formatarValor(item.ValorFinal / item.quantidade),
    "VT. DEVOLVIDO": formatarValor(item.valorDevolucao),
    "Desconto": formatarValor(item.VDesconto),
    "Frete": formatarValor(item.Frete),
    "Outras Despesas": formatarValor(item.vOutrasDespesas),
    "ST": formatarValor(item.vTotalST),
    "IPI": formatarValor(item.vTotalIPI),
    "FCOEP": formatarValor(item.vFCPST_Valor),
    // "V. LIQUIDO": 
    // "" : item.vValorFrete,
  }));
}


function gerarExcel(dados, objeto) {
  const formatarValorParaExcel = (valor) => {
    if (typeof valor === "string" && valor.includes("R$")) {
      const valorLimpo = valor
        .replace("R$", "") // Remove "R$"
        .replace(/\s/g, "") // Remove espaços
        .replace(/\./g, "") // Remove separadores de milhares
        .replace(",", "."); // Substitui vírgula decimal por ponto decimal
      return parseFloat(valorLimpo) || 0; // Converte para número e retorna 0 se NaN
    }
    return valor; // Retorna o valor original se não for string no formato "R$"
  };

  // Mapear e formatar os dados para Excel
  const dadosFormatados = dados.map((item) => {
    const itemFormatado = { ...item };
    for (const chave in itemFormatado) {
      if (typeof itemFormatado[chave] === "string" && itemFormatado[chave].includes("R$")) {
        itemFormatado[chave] = formatarValorParaExcel(itemFormatado[chave]);
      }
    }
    return itemFormatado;
  });

  // Criar workbook e worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dadosFormatados);


  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'quadro_diario.xlsx');
}


function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

function getMesAtual() {
  const now = new Date();
  const mesAtual = String(now.getMonth() + 1).padStart(2, '0'); // +1 porque os meses começam de zero
  return mesAtual;
}

function obterAnoAtual() {
  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();
  return anoAtual;
}


export default function UserReports() {
  const [value, setValue] = useState([])
  const [analitico, setAnalitico] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [status, setStatus] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [origem, setOrigens] = useState('');
  const [prod, setFiltroProd] = useState('');
  const [categVenda, setCategVenda] = useState('');
  const [mes, setMes] = useState(getMesAtual());
  const [vendedor, setVendedor] = useState('');
  const [tabela, setTabela] = useState('');
  const [diaAtual, setDia] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [TipoData, setTipoData] = useState('Data');
  const [generoProd, setGeneroProd] = useState("00 – Mercadoria para Revenda");
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [anos, setAnos] = useState();

  const options = [
    { value: 'Pedido', label: 'Pedido' },
    { value: 'Orçamento', label: 'Orçamento' },
    { value: 'Pedido Faturado', label: 'Pedido Faturado' },
    { value: 'Pedido Nao Faturado', label: 'Pedido Nao Faturado' },
    { value: 'Pedido Aprovado Sem Faturamento', label: 'Pedido Aprovado Sem Faturamento' },
  ];

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {

    const dados_formatados = await generateTableData(data, diaAtual)
    setCampos(dados_formatados)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    // console.log(dados_formatados)
    const totais = calcularTotalizadores(objFinal)

    // console.log(totais)

    // console.log("dados formatados")
    // console.log(objFinal, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  const meses = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/analitico_filtros', { params: param, timeout: 500000 }),
            api.get('/quadro_vendas', { params: param, timeout: 5000000 }),
          ]);

          const filtros = filtrosData.data;
          const dados = dadosData.data;

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);
          setAnos(obterAnoAtual());

          const select_empresas = [...new Set(filtros[0].map(item => item.NomeFantasia))];
          const select_origens = [...new Set(filtros[1].map(item => item.origemVenda))];
          const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria))];
          const select_dep = [...new Set(filtros[4].map(item => item.Deposito))];
          const select_tab = [...new Set(filtros[5].map(item => item.TabelaDePreco))];
          const select_categ_prod = [...new Set(filtros[6].map(item => item.Categoria))];
          const select_genero_prod = [...new Set(filtros[7].map(item => item.Genero))];
          const years = [...new Set(filtros[8].map(item => item.ano))];

          setDia(filtros[3]);

          const selectEmpresas = document.getElementById("select-empresas");
          select_empresas.forEach(empresa => {
            const option = document.createElement("option");
            option.value = empresa;
            option.text = empresa;
            selectEmpresas.appendChild(option);
          });

          const selectOrigens = document.getElementById("select-origens");
          select_origens.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectOrigens.appendChild(option);
          });

          const selectCateg = document.getElementById("select-categoria-venda");
          select_categ.forEach(categVenda => {
            const option = document.createElement("option");
            option.value = categVenda;
            option.text = categVenda;
            selectCateg.appendChild(option);
          });

          const selectYears = document.getElementById("years");
          years.forEach(ano => {
            const option = document.createElement("option");
            option.value = ano;
            option.text = ano;
            selectYears.appendChild(option);
          });

          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((status !== '') && status !== null) {
        data.status = status
      }

      if ((value !== '') && value !== null) {
        data.multi = value
      }

      if ((empresa !== '') && empresa !== null) {
        data.empresa = empresa
      }

      if ((vendedor !== '') && vendedor !== null) {
        data.vendedor = vendedor
      }

      if ((generoProd !== '') && generoProd !== null) {
        data.genero = generoProd
      }

      if ((origem !== '') && origem !== null) {
        data.origem = origem
      }

      if ((TipoData !== '') && TipoData !== null) {
        data.TipoData = TipoData
      }

      if ((prod !== '') && prod !== null) {
        data.prod = prod
      }

      if ((categVenda !== '') && categVenda !== null) {
        data.categVenda = categVenda
      }

      if ((mes !== '') && mes !== null) {
        data.mes = mes
      }

      if ((typeof anos !== 'undefined') && anos !== null) {
        data.anos = anos
      }

      // if (inicio !== null && inicio !== '') {
      //   if (fim !== null && fim !== '') {
      //     data.fim = fim
      //     data.inicio = inicio
      //   } else {
      //     alert("preencher os dois periodos");
      //   }
      // }

      setFiltros(data)

      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/quadro_vendas', { params: data, timeout: 1000000 })
          .then(response => {
            // console.log(response.data)

            if (response.data.length !== 0) {
              // console.log(response.data[0])
              setDados(response.data);
              setTotalItems(response.data.length);
            } else {
              setDados(response.data);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid
        columns={[1, 2, 3, 4]}
        spacing={5} mb={5} ml={2}
        bg="white" rounded="md" p={4}
        border="1px solid black"
      >
        <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Origem da Venda:</FormLabel>
            <Select id="select-origens" onChange={(e) => setOrigens(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda:</FormLabel>
            <Select onChange={(e) => setStatus(e.target.value)}>
              <option value="Pedido Faturado">Pedido Faturado</option>
              <option value="all">Todos</option>
              <option value="Pedido">Pedido</option>
              <option value="Orçamento">Orçamento</option>
              <option value="Pedido Nao Faturado">Pedido não Faturado</option>
              <option value="Pedido Aprovado Sem Faturamento">Pedido Aprovado Sem Faturamento</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Status da Venda:</FormLabel>
            <MultiSelect
              options={options}
              value={value}
              onChange={setValue}
              // hasSelectAll={false}
              disableSearch={true}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos.",
                  "clearSearch": "Clear Search",
                  "clearSelected": "Clear Selected",
                  "noOptions": "No options",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Create",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Categoria da Venda:</FormLabel>
            <Select id="select-categoria-venda" onChange={(e) => setCategVenda(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Vendedor:</FormLabel>
            <Input type="input" id="prod" onChange={(e) => setVendedor(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="Data">Data da Venda</option>
              <option value="DataAprovacao">Data Aprovação</option>
              <option value="DataFaturamento">Data Faturamento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Selecione Ano:</FormLabel>
            <Select value={anos} bg="white" id="years" onChange={(e) => setAnos(e.target.value)}>
              {/* <option value="">Todas</option> */}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Selecione Mês:</FormLabel>
            <Select id="mes" value={mes} onChange={(e) => setMes(e.target.value)}>
              <option value="">Selecione o mês</option>
              {meses.map((mes) => (
                <option key={mes.value} value={mes.value}>
                  {mes.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box> */}
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Filtrar</Button>
        </Box>
      </SimpleGrid>

      <SimpleGrid mt='1%'>
        <Button size="sm" w="10%" 
        onClick={handleOpenModal}
        border="1px solid black"
        >Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        {/* <ModalComponent data={analitico} onValuesSelected={handleValuesSelected} isOpen={isOpen} onClose={handleCloseModal} valuesCheck={selectedValues} /> */}
        <Box mt='1%'>
          <Text fontWeight="bold">Valores Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='2%'>
        <TableContainer w='96%' ml='2%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}

import api from 'contexts/api';
import { Box, FormLabel, FormControl, Input, Icon, Select, SimpleGrid, Button, Text, Spinner } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from '@chakra-ui/icons';
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import Pagination from 'views/admin/AnaliticoProduto/components/paginate';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as XLSX from 'xlsx/xlsx.mjs';

import { MultiSelect } from "react-multi-select-component";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import DataDisplay from '../AnaliticoProduto/components/table';
import ModalComponent from '../AnaliticoProduto/components/modal';


const TotalsRow = ({ data }) => {
  return (
    <Tr>
      {/* <Td colSpan={2}>Totais:</Td> */}
      {data.map((item, index) => (
        // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
        index === 0 ? (
          <Td key={index} fontWeight="bold">Totais:</Td>
        ) : (
          <>
            <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
            {/* {index === 10 && <Td key="empty" />}  */}
            {/* Adiciona uma <Td> vazia quando index for igual a 10 */}
          </>
          // <Td key={index} textAlign="center">{Object.values(item)[0]}</Td>
        )
      ))}
    </Tr>
  );
};


// function getPayment(valor) {
//   // console.log(valor)
//   if (Boolean(valor) === true) {
//     return <Text color="green">Pago</Text>;
//   } else {
//     return <Text color="red">Não Pago</Text>;
//   }
// }

function getPayment(valor) {
  if (Boolean(valor) === true) {
    return <span style={{ color: 'green' }}>Pago</span>;
  } else {
    return <span style={{ color: 'red' }}>Não Pago</span>;
  }
}


function validarData(data) {
  const dateObj = new Date(data);

  if (dateObj.toString() === 'Invalid Date' || dateObj.getFullYear() <= 1) {
    return '';
  }

  return data;
}


//verifica validade do lancamento
function verificarStatus(data) {
  const dataAtual = new Date();
  const dataVencimento = new Date(data);
  dataVencimento.setHours(23, 59, 59, 999); // Definir hora final do dia de vencimento

  const diffTime = Math.abs(dataVencimento - dataAtual);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (dataVencimento < dataAtual) {
    const mensagem = `Vencido ${diffDays} dias`;

    return (
      <Text color="red">
        {mensagem}
      </Text>
    );
  }

  return (
    <Text color="green">
      Em dia
    </Text>
  );
}

function generatePDF(excel, total, filtros) {

  // console.log(filtros)

  let tableFiltro = transformaFiltros([filtros]);
  let result = [];
  // for (let i = 0; i < tableFiltro.length; i++) {
  //   const rowValues = Object.values(tableFiltro[i]);
  //   console.log(rowValues)
  //   const filtrosConvertidos = rowValues.reduce((acc, value, index) => {
  //     let convertedValue = value;
  //     if (typeof value === 'object' && value !== null && value.props && value.props.children) {
  //       convertedValue = value.props.children.toString(); // Converte o objeto em uma string
  //     }
  //     const key = Object.keys(rowValues)[index];
  //     acc.push({ [key]: convertedValue });
  //     return acc;
  //   }, []);
  //   result.push(filtrosConvertidos);
  // }
  for (let i = 0; i < tableFiltro.length; i++) {
    const rowEntries = Object.entries(tableFiltro[i]);
    const filtrosConvertidos = rowEntries.reduce((acc, [key, value]) => {
      let convertedValue = value;
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        convertedValue = value.props.children.toString(); // Converte o objeto em uma string
      }
      acc.push({ [key]: convertedValue });
      return acc;
    }, []);
    result.push(filtrosConvertidos);
  }


  const doc = new jsPDF('landscape');
  let tableData = [];

  const data = excel;
  const headers = Object.keys(data[0]).slice(0, 12);
  const headers_total = Object.values(total).slice(0, 12).map(obj => Object.values(obj)[0]);

  for (let i = 0; i < data.length; i++) {
    const rowValues = Object.values(data[i]).slice(0, 12);
    const convertedRowValues = rowValues.map((value) => {
      if (typeof value === 'object' && value !== null && value.props && value.props.children) {
        return value.props.children.toString(); // Converte o objeto em uma string
      }
      return value;
    });
    tableData.push(convertedRowValues);
  }


  doc.setFontSize(18);
  doc.text('Lançamentos Financeiros', 15, 15);

  doc.setFontSize(10);
  doc.text('Filtros:', 15, 25);

  const filters = result[0]; // Obtém os filtros do resultado

  if (filters) {
    let currentX = 35; // Posição X inicial para os valores dos filtros
    const y = 25; // Posição Y para a primeira linha dos filtros

    const mergedFilters = filters.reduce((acc, filter) => {
      const [label, value] = Object.entries(filter)[0]; // Obtém a legenda e o valor do filtro
      return `${acc}${label} ${value}, `; // Concatena a legenda e o valor com vírgula
    }, '');

    // Remove a vírgula extra no final
    const mergedFiltersText = mergedFilters.slice(0, -2);

    // Define o tamanho da fonte para os filtros
    doc.setFontSize(8);

    // Verifica se o texto dos filtros cabe em uma linha
    const textWidth = doc.getStringUnitWidth(mergedFiltersText) * doc.internal.getFontSize();
    const maxWidth = doc.internal.pageSize.getWidth() - currentX - 10; // Largura máxima disponível
    let filteredText = mergedFiltersText;

    if (textWidth > maxWidth) {
      // O texto excede a largura máxima, então é necessário quebrar em várias linhas
      const lines = doc.splitTextToSize(mergedFiltersText, maxWidth);
      filteredText = lines.join('\n'); // Concatena as linhas com quebras de linha
    }

    // Adiciona o texto dos filtros no documento PDF
    doc.text(filteredText, currentX, y);
  }

  // Define a margem superior da tabela
  const tableMarginTop = 35;

  // Adicionar cabeçalhos
  doc.autoTable({
    head: [headers_total, headers], // Adicione os cabeçalhos em ordem
    body: tableData,
    startY: tableMarginTop, // Ajuste a posição vertical para evitar sobreposição com o título e a legenda
    columnWidth: 'auto',
    styles: {
      fontSize: 6,
      cellStyles: {
        overflow: 'linebreak', // Quebra de linha automática para células
      },
    },
  });

  doc.save('lancamentos_financeiro.pdf');
}


function calcularTotalizadores(array) {
  const totalizadores = {};


  array.forEach((obj) => {
    for (const key in obj) {
      const valor = obj[key];

      if (typeof valor === 'string' && valor.includes('R$')) {
        const valorNumerico = parseFloat(valor.replace(/[^\d,-]+/g, '').replace(',', '.'));
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else if (key === 'Qtd. Utilizadas') {
        const valorNumerico = parseFloat(valor);
        if (!isNaN(valorNumerico)) {
          if (totalizadores[key]) {
            totalizadores[key] += valorNumerico;
          } else {
            totalizadores[key] = valorNumerico;
          }
        }
      } else {
        if (!totalizadores[key]) {
          totalizadores[key] = '';
        }
      }
    }
  });

  const resultado = [];

  for (const key in totalizadores) {
    let value = totalizadores[key];
    if (key !== 'Qtd. Utilizadas') {
      value = totalizadores[key] !== '' ? `R$ ${totalizadores[key].toFixed(2)}` : '';
    }
    resultado.push({
      [`Total ${key}`]: value,
    });
  }

  return resultado;
}

const formatDate = (date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  let day = date.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`;
};

function formatarDataHora(valor) {

  // console.log(valor)

  if (valor === null || valor === undefined) {
    return "";
  }

  const data = new Date(valor);

  if (isNaN(data) || data.toString().includes("Invalid Date") || data.getFullYear() === 0) {
    return "";
  }

  const dia = ("0" + data.getDate()).slice(-2);
  const mes = ("0" + (data.getMonth() + 1)).slice(-2);
  const ano = data.getFullYear();
  const horas = ("0" + data.getHours()).slice(-2);
  const minutos = ("0" + data.getMinutes()).slice(-2);

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}

function transformaFiltros(data) {
  return data.map((item) => {
    const filtro = {};

    if (item.tipolancamento) {
      filtro['Tipo: '] = obterTipoLancamentoString(item.tipolancamento);
    }

    if (item.empresa) {
      filtro['Empresa: '] = item.empresa;
    }

    if (item.situacao) {
      filtro['Quitado: '] = getPayment(item.situacao);
    }

    if (item.tipoData) {
      filtro['Tipo Data: '] = item.tipoData;
    }

    if (item.formas) {
      filtro['Forma Pagamento: '] = item.formas;
    }

    if (item.grupo) {
      filtro['Grupo Lançamento: '] = item.grupo;
    }

    if (item.cliente) {
      filtro['Cliente: '] = item.cliente;
    }

    if (item.grupoPessoa) {
      filtro['Grupo do Cliente: '] = item.grupoPessoa;
    }

    if (item.centro) {
      filtro['Centro de Custo: '] = item.centro;
    }

    if (item.banco) {
      filtro['Banco: '] = item.banco;
    }

    if (item.cidade) {
      filtro['Cidade: '] = item.cidade;
    }

    if (item.uf) {
      filtro['UF:'] = item.uf;
    }

    if (item.vendedor) {
      filtro['Vendedor: '] = item.vendedor;
    }

    if (item.plano) {
      filtro['Plano de Contas: '] = item.plano;
    }

    if (item.inicio) {
      filtro['Data Inicio: '] = formatarDataHora(item.inicio);
    }

    if (item.fim) {
      filtro['Data Final: '] = formatarDataHora(item.fim);
    }

    return filtro;
  });
}

function generateTableData(analitico) {
  if (analitico.length === 0) {
    const keys = [
      'Codigo Sequencial',
      'Empresa',
      'Cliente/ Fornecedor',
      'Grupo do Cliente',
      'Vendedor',
      'Criado Por',
      'Tipo',
      'Quitado',
      'Numero Documento',
      'Valor Previsto',
      'Valor Pago',
      'Juros',
      'Juros Antecipado',
      'Multa',
      'Saldo Atual',
      'Data Competencia',
      'Data Vencimento',
      'Data Pagamento',
      'Status',
      'Lancamento Grupo',
      'Forma Pagamento',
      'Centro de Custo',
      'Plano De Conta',
      'Banco',
      'UF',
      'Cidade',
      'Modificado Por',
      'Observação'
    ];

    const emptyObject = {};
    keys.forEach(key => {
      emptyObject[key] = '';
    });

    return [emptyObject];
  }

  return analitico.map((item) => ({
    'Codigo Sequencial': item.CodigoSequencial,
    'Empresa': item.Empresa,
    'Cliente/ Fornecedor': item.Cliente,
    'Grupo do Cliente': item.PessoaGrupo,
    'Vendedor': item.Vendedor,
    'Criado Por': item.CriadoPor,
    'Tipo': obterTipoLancamentoString(item.Despesa),
    'Quitado': getPayment(item.Pago),
    'Numero Documento': item.NumeroDocumento,
    'Valor Previsto': formatarValor(item.Entrada + item.Saida),
    'Valor Pago': formatarValor(item.ValorPago),
    'Juros': formatarValor(item.Juros),
    'Juros Antecipado': formatarValor(item.JurosAntecipacao),
    'Multa': formatarValor(item.Multa),
    'Saldo Atual': formatarValor((item.Entrada + item.Saida) - item.ValorPago),
    'Data Competencia': formatarDataHora(item.DataCompetencia),
    'Data Vencimento': formatarDataHora(item.DataVencimento),
    'Data Pagamento': formatarDataHora(item.DataPagamento),
    'Status': item.Pago ? "Pago" : verificarStatus(item.DataVencimento),
    'Lancamento Grupo': item.LancamentoGrupo,
    'Forma Pagamento': item.FormaPagamento,
    'Centro de Custo': item.CentroDeCusto,
    'Plano De Conta': item.PlanoDeConta,
    'Banco': item.Banco,
    'UF': item.UF,
    'Cidade': item.Cidade,
    'Modificado Por': item.ModificadoPor,
    'Observação': item.Observacoes
  }));
}

function gerarExcel(dados, objeto) {

  const formatarValorParaExcel = (valor) => {
    if (typeof valor === "string" && valor.includes("R$")) {
      const valorLimpo = valor
        .replace("R$", "") // Remove "R$"
        .replace(/\s/g, "") // Remove espaços
        .replace(/\./g, "") // Remove separadores de milhares
        .replace(",", "."); // Substitui vírgula decimal por ponto decimal
      return parseFloat(valorLimpo) || 0; // Converte para número e retorna 0 se NaN
    }
    return valor; // Retorna o valor original se não for string no formato "R$"
  };

  const dadosFormatados = dados.map((item) => {
    const itemFormatado = { ...item };
    for (const chave in itemFormatado) {
      if (typeof itemFormatado[chave] === "string" && itemFormatado[chave].includes("R$")) {
        itemFormatado[chave] = formatarValorParaExcel(itemFormatado[chave]);
      }
    }
    return itemFormatado;
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dadosFormatados);

  // Extrair os valores do objeto
  const objetoValores = Object.values(objeto).map(item => Object.values(item)[0]);

  // Obter a matriz existente dos dados
  const matrizDados = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  // Mesclar as matrizes (inserir a nova matriz acima da matriz existente)
  const matrizFinal = [objetoValores, ...matrizDados];

  // Converter a matriz final em uma planilha
  const novaWorksheet = XLSX.utils.aoa_to_sheet(matrizFinal);

  // Adicionar a nova planilha ao workbook
  XLSX.utils.book_append_sheet(workbook, novaWorksheet, 'Sheet 1');

  XLSX.writeFile(workbook, 'relatorio_financeiro.xlsx');
}

function formatarValor(valor) {
  if (valor === null || valor === undefined) {
    return "R$ 00,00";
  }

  const valorFormatado = valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormatado;
}

function obterTipoLancamentoString(valor) {
  // console.log(valor)
  if (Boolean(valor) === true) {
    return <Text color="red">Despesa</Text>;
  } else {
    return <Text color="green">Receita</Text>;
  }
}

function removeObjectsByKey(objects, keysToRemove) {
  return objects.map(obj => {
    const newObj = {};
    for (let key in obj) {
      if (!keysToRemove.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
}

export default function UserReports() {
  const [analitico, setAnalitico] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [tipolancamento, setTipoLancamento] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [grupo, setGrupo] = useState('');
  const [centro, setCentro] = useState('');
  const [cliente, setCliente] = useState('');
  const [tipoData, setTipoData] = useState('DataCompetencia');
  const [situacao, setSituacao] = useState('');
  const [vendedores, setVendedores] = useState('');
  const [banco, setBancos] = useState('');
  const [uf, setUF] = useState('');
  const [cidade, setCidade] = useState('');
  const [formas, setFormas] = useState('');
  const [filtros, setFiltros] = useState({});
  const [totalItems, setTotalItems] = useState(0);
  const [inicio, setInicio] = useState('');
  const [fim, setFim] = useState('');
  const [plano, setPlano] = useState('');
  const [grupoPessoa, setGrupoPessoa] = useState('');
  const history = useHistory();
  const [token, setToken] = useState('');
  const [dados, setDados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState('');
  const [excel, setExcel] = useState('');
  const [criadores, setCriadores] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [camposTotal, setCampos] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);

  const [selectEmpresas, setSelectEmpresas] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const [selectedVendedores, setSelectedVendedores] = useState([]);
  const [selectCriadores, setSelectedCriadores] = useState([]);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleValuesSelected = (values) => {
    setSelectedValues(values);
  };

  const handleClickExcel = () => {
    gerarExcel(excel, total);
  };

  const handleClickPdf = () => {
    generatePDF(excel, total, filtros);
  };

  async function index(data) {

    const dados_formatados = await generateTableData(data)
    setCampos(dados_formatados)
    const objFinal = removeObjectsByKey(dados_formatados, selectedValues)
    const totais = calcularTotalizadores(objFinal)
    // console.log("dados formatados")
    // console.log(dados_formatados, totais)
    setExcel(objFinal)
    setTotal(totais)

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToShow = objFinal.slice(startIndex, endIndex);
    // console.log(itemsToShow);
    setAnalitico(itemsToShow)
  }

  const getData = async () => {
    try {
      const value = await AsyncStorage.getItem('@storage_Key')
      if (value !== null) {
        return value;// value previously stored
      }
    } catch (e) {
      // error reading value
    }
  }

  useEffect(async () => {
    const param = {};
    const key = await getData();

    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDayOfMonth);
    const formattedLastDay = formatDate(lastDayOfMonth);

    setInicio(formattedFirstDay);
    setFim(formattedLastDay);

    if (typeof key !== 'undefined' && key !== null) {
      param.user = key;
      setFiltros(param);

      const fetchData = async () => {
        setIsLoading(true);

        try {
          const [filtrosData, dadosData] = await Promise.all([
            api.get('/finance-filter', { params: param, timeout: 500000 }),
            api.get('/analityc_finance', { params: param, timeout: 5000000 }),
          ]);

          // console.log(filtrosData)

          const filtros = filtrosData.data;
          const dados = dadosData.data;

          setToken(key);
          setDados(dados);
          setTotalItems(dados.length);

          const select_empresas = filtros[0];
          // [...new Set(filtros[0].map(item => item.NomeFantasia))];
          const select_grupo = [...new Set(filtros[1].map(item => item.Nome))];
          const select_centro = [...new Set(filtros[2].map(item => item.Nome))];
          const select_plano = [...new Set(filtros[3].map(item => item.PlanoDeConta))];
          const select_grupo_pessoa = [...new Set(filtros[4].map(item => item.Nome))];
          const select_banco = [...new Set(filtros[5].map(item => item.Nome))];
          const select_formas = [...new Set(filtros[6].map(item => item.Nome))];
          //   const select_categ = [...new Set(filtros[2].map(item => item.VendaCategoria))];

          const vendedoresFiltros = filtros[7];
          const cp = filtros[8];

          if (vendedoresFiltros) {
            const vend = vendedoresFiltros.map(vendedor => ({
              value: vendedor._id,
              label: vendedor.NomeFantasia
            }));
            setVendedores(vend);
            // console.log(vend); // Aqui você verá os dados atualizados
          }

          if (cp) {
            const criado = cp.map(criadop => ({
              value: criadop._id,
              label: criadop._id
            }));
            setCriadores(criado);
            // console.log(vend); // Aqui você verá os dados atualizados
          }


          // const selectEmpresas = document.getElementById("select-empresas");
          // select_empresas.forEach(empresa => {
          //   const option = document.createElement("option");
          //   option.value = empresa;
          //   option.text = empresa;
          //   selectEmpresas.appendChild(option);
          // });

          if (select_empresas) {
            const empresa = select_empresas.map(empresa => ({
              value: empresa._id,
              label: empresa.NomeFantasia
            }));
            setEmpresas(empresa);
            // console.log(vend); // Aqui você verá os dados atualizados
          }


          const selectGrupo = document.getElementById("select-grupo");
          select_grupo.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectGrupo.appendChild(option);
          });

          const selectCentro = document.getElementById("select-centro");
          select_centro.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectCentro.appendChild(option);
          });

          const selectPlano = document.getElementById("select-plano");
          select_plano.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectPlano.appendChild(option);
          });

          const selectGrPessoa = document.getElementById("select-grupo-pessoa");
          select_grupo_pessoa.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectGrPessoa.appendChild(option);
          });

          const selectBanco = document.getElementById("select-banco");
          select_banco.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectBanco.appendChild(option);
          });

          const selectFormas = document.getElementById("select-formas");
          select_formas.forEach(origens => {
            const option = document.createElement("option");
            option.value = origens;
            option.text = origens;
            selectFormas.appendChild(option);
          });

          //   const selectCateg = document.getElementById("select-categoria-venda");
          //   select_categ.forEach(categVenda => {
          //     const option = document.createElement("option");
          //     option.value = categVenda;
          //     option.text = categVenda;
          //     selectCateg.appendChild(option);
          //   });


          if (currentPage === 1) {
            await index(dados);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      history.push('/');
    }
  }, []);

  // console.log(analitico)

  //executa a paginação na alteração de seus estados
  useEffect(() => {
    index(dados);
  }, [currentPage, itemsPerPage, selectedValues]);


  //aplica os filtros
  const handleFilter = async () => {
    const data = {}

    if ((typeof token !== 'undefined') && token !== null) {
      data.user = token

      if ((tipolancamento !== '') && tipolancamento !== null) {
        data.tipolancamento = tipolancamento
      }

      if ((selectEmpresas !== '') && selectEmpresas !== null) {
        data.empresa = selectEmpresas
      }


      if ((selectCriadores !== '') && selectCriadores !== null) {
        data.criado_por = selectCriadores
      }

      // if ((empresa !== '') && empresa !== null) {
      //   data.empresa = empresa
      // }

      if ((situacao !== '') && situacao !== null) {
        data.situacao = situacao
      }

      // console.log(tipoData)

      if ((tipoData !== '') && tipoData !== null) {
        data.tipoData = tipoData
      }

      if ((uf !== '') && uf !== null) {
        data.uf = uf
      }

      if ((cidade !== '') && cidade !== null) {
        data.cidade = cidade
      }

      if ((formas !== '') && formas !== null) {
        data.formas = formas
      }

      if ((grupo !== '') && grupo !== null) {
        data.grupo = grupo
      }

      if ((grupoPessoa !== '') && grupoPessoa !== null) {
        data.grupoPessoa = grupoPessoa
      }

      if ((centro !== '') && centro !== null) {
        data.centro = centro
      }

      if ((cliente !== '') && cliente !== null) {
        data.cliente = cliente
      }

      if ((banco !== '') && banco !== null) {
        data.banco = banco
      }

      if ((selectedVendedores !== '') && selectedVendedores !== null) {
        data.vendedor = selectedVendedores
      }

      if ((plano !== '') && plano !== null) {
        data.plano = plano
      }

      if (inicio !== null && inicio !== '') {
        if (fim !== null && fim !== '') {
          data.fim = fim
          data.inicio = inicio
        } else {
          alert("preencher os dois periodos");
        }
      }

      setFiltros(data)


      if (Object.keys(data).length !== 0) {
        setCurrentPage(1)
        setItemsPerPage(20)
      }

      try {
        setIsLoading(true);
        api.get('/analityc_finance', { params: data, timeout: 500000 })
          .then(response => {
            // console.log(response.data)

            if (response.data.length !== 0) {
              // console.log(response.data[0])
              setDados(response.data);
              setTotalItems(response.data.length);
            } else {
              setDados(response.data);
              handlePageChange(1)
              setTotalItems(20);
            }

            if (currentPage === 1) {
              index(response.data);
            }
          })
          .catch(error => {
            console.error(error);
          }).finally(() => {
            setIsLoading(false)
          });;

        // console.log(data[0].length)
      } catch (error) {
        console.error(error);
      }
    } else {
      history.push('/');
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    // handleSubmit(); // Atualiza os dados ao alterar a página
  };


  const handleItemsPerPageChange = (newItens) => {
    setItemsPerPage(newItens);
    setCurrentPage(1); // Reset currentPage when changing items per page
    // handleSubmit();
  };


  return (
    <Box mt={{ sm: '140px', md: '12%', lg: '15%', xl: '6%' }}>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.5)" // Define a cor de fundo como transparente com um leve tom escuro (50% de opacidade)
          zIndex="9999"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="blue.500"
            size="xl"
          />
        </Box>
      )}
      <SimpleGrid columns={[1, 2, 3, 4]} spacing={5} mb={5} ml={2} bg="white" rounded="md" p={4}>
      <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Criado por:</FormLabel>
            <MultiSelect
              options={criadores}
              value={selectCriadores}
              onChange={setSelectedCriadores}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Grupo Lançamento:</FormLabel>
            <Select id="select-grupo" onChange={(e) => setGrupo(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        <Box maxW="md">
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Grupo do CLiente:</FormLabel>
            <Select id="select-grupo-pessoa" onChange={(e) => setGrupoPessoa(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        {/* <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <Select id="select-empresas" onChange={(e) => setEmpresa(e.target.value)}>
              <option value="">Todas</option>
            </Select>
          </FormControl>
        </Box> */}
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Empresas:</FormLabel>
            <MultiSelect
              options={empresas}
              value={selectEmpresas}
              onChange={setSelectEmpresas}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">UF:</FormLabel>
            <Select id="select-uf" onChange={(e) => setUF(e.target.value)}>
              <option value="">Todas</option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Cidade:</FormLabel>
            <Input type="input" id="cidade" onChange={(e) => setCidade(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Lançamento:</FormLabel>
            <Select onChange={(e) => setTipoLancamento(e.target.value)}>
              <option value="">Todos</option>
              <option value="true">Despesa</option>
              <option value="false">Receita</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Situação:</FormLabel>
            <Select onChange={(e) => setSituacao(e.target.value)}>
              <option value="">Todos</option>
              <option value="true">Quitado</option>
              <option value="false">Não Quitado</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Formas de Pagamento:</FormLabel>
            <Select id="select-formas" onChange={(e) => setFormas(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Centro de Custo:</FormLabel>
            <Select id="select-centro" onChange={(e) => setCentro(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Plano de Conta:</FormLabel>
            <Select id="select-plano" onChange={(e) => setPlano(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Bancos:</FormLabel>
            <Select id="select-banco" onChange={(e) => setBancos(e.target.value)}>
              <option value="">Todos</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Cliente/Fornecedor:</FormLabel>
            <Input type="input" id="cliente" onChange={(e) => setCliente(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Vendedores:</FormLabel>
            <MultiSelect
              options={vendedores}
              value={selectedVendedores}
              onChange={setSelectedVendedores}
              hasSelectAll={false}
              // hasSelectAll={false}
              disableSearch={false}
              overrideStrings={
                {
                  "allItemsAreSelected": "Todos",
                  "clearSearch": "Limpar Pesquisa",
                  "clearSelected": "Limpar Selecionado",
                  "noOptions": "Sem opções",
                  "search": "Pesquisar",
                  "selectAll": "Todos",
                  "selectAllFiltered": "Select All (Filtered)",
                  "selectSomeItems": "Selecione",
                  "create": "Criar",
                }
              }
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Tipo Data:</FormLabel>
            <Select id="select-data" onChange={(e) => setTipoData(e.target.value)}>
              <option value="DataCompetencia">Data Competencia</option>
              <option value="DataPagamento">Data Pagamento</option>
              <option value="DataVencimento">Data Vencimento</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Início do Período:</FormLabel>
            <Input type="date" id="inicio" value={inicio} onChange={(e) => setInicio(e.target.value)} />
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel fontSize={{ sm: "12px" }} fontWeight="bold">Fim do Período:</FormLabel>
            <Input type="date" id="Fim" value={fim} onChange={(e) => setFim(e.target.value)} />
          </FormControl>
        </Box>
        <Box mt="auto">
          <Button colorScheme="blue" onClick={handleFilter}>Enviar</Button>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='1%'>
        <Button size="sm" w="10%" onClick={handleOpenModal}>Selecionar Campos</Button>
        <ModalComponent
          data={camposTotal}
          onValuesSelected={handleValuesSelected}
          isOpen={isOpen}
          onClose={handleCloseModal}
          selectedValues={selectedValues}
        />
        {/* <ModalComponent data={analitico} onValuesSelected={handleValuesSelected} isOpen={isOpen} onClose={handleCloseModal} valuesCheck={selectedValues} /> */}
        <Box mt='1%'>
          <Text fontWeight="bold">Campos Retirados: {selectedValues.length > 0 ? selectedValues.join(', ') : 'Nenhum valor selecionado'}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid mt='5%'>
        <TableContainer w='96%' ml='2%' overflowX="auto" >
          {analitico && total && (
            <DataDisplay data={analitico} total={total} />
          )}
        </TableContainer>
      </SimpleGrid>
      <Box display='flex'>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
          onLimitChange={handleItemsPerPageChange}
        />
        <Button mt={4} colorScheme="blue" mb="5%" mr={1} size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickExcel}>Excel</Button>
        <Button mt={4} colorScheme="blue" mb="5%" size="sm" fontSize={{ sm: "10px" }} leftIcon={<DownloadIcon />} onClick={handleClickPdf}>PDF</Button>
      </Box>
    </Box>
  );
}
